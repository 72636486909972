export default [
  {
    title: 'Génération',
    route: 'espace-tests.generation',
    icon: 'CheckSquareIcon',

  },
  // {
  //   title: 'Vérification',
  //   route: 'espace-tests.verification',
  //   icon: 'HomeIcon',
  // },
]
